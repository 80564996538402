<template>
  <div class="pdf review-information">
    <q-page-container>
      <q-page>
        <div class="flex">
          <div class="left-panel">
            <div class="entity-block">
              <div class="flex top-panel">
                <div class="wrapper-button">
                  <q-btn
                    class="btn-new"
                    icon-right="add"
                    label="Ajouter une entité"
                    @click="openAddEntity"
                  />
                </div>
                <SearchInformation
                  @setSearchText="setSearchText"
                  placeholder="Rechercher"
                  :loading="true"
                />
              </div>
              <div v-if="isAddEntity">
                <AddEntityBlock
                  :projectId="projectId"
                  @close="closeAddEntity"
                  @newEntity="newEntity"
                />
              </div>
              <div class="entities-list">
                <template v-for="(entity, index) in entitiesProject">
                  <EntityBlock
                    :data-entity-id="entity.entityId"
                    :selected="selected"
                    :selectedDataId.sync="selectedDataId"
                    :selectedVariantDataId="selectedVariantDataId"
                    :selectedEntityId="selectedEntityId"
                    :index="index"
                    @selected="selectData"
                    @sourceDocumentId="checkSourceDocumentId"
                    :key="entity.entityId"
                    :entity="entity"
                    :search="search"
                    @selectedVariant="selectVariant"
                  />
                </template>
              </div>
            </div>
          </div>
          <div class="right-panel">
            <PdfBlock
              :errorSourceDocumentId="errorSourceDocumentId"
              :selectedDataId="selectedVariantDataId"
              :selectedEntityId="selectedEntityId"
              :document="getDocument"
              :extractedData="extractedData"
              :entitiesProject="entitiesProject"
              :selectedDataLabel="selectedDataLabel"
              labelSaveButton="Sauvegarder"
              @saved="saved"
            />
          </div>
          <div
            class="error-document"
            v-if="getDocument === null && selectedDataId === null"
          >
            Veuillez choisir une information dans la colonne de gauche
          </div>
          <div class="error-document" v-if="errorSourceDocumentId">
            <span>
              Une erreur s'est produite sur ce document
            </span>
          </div>
        </div>
      </q-page>
    </q-page-container>
  </div>
</template>

<script>
import Vue from 'vue'
import { DocumentEditorPlugin } from '@syncfusion/ej2-vue-documenteditor'
import { mapGetters, mapActions, mapState } from 'vuex'
import EntityBlock from '@/components/review-information/EntityBlock'
import PdfBlock from '@/components/global/PdfBlock'
import SearchInformation from '@/components/review-information/SearchInformation'
import AddEntityBlock from '@/components/review-information/AddEntityBlock'
import isEqual from 'lodash.isequal'

Vue.use(DocumentEditorPlugin)

export default {
  components: {
    EntityBlock,
    PdfBlock,
    SearchInformation,
    AddEntityBlock
  },
  data() {
    return {
      extractedData: [],
      entitiesProject: [],
      expanded: false,
      selectedDataId: null,
      selectedDataLabel: null,
      selectedEntityId: null,
      isAddEntity: false,
      isAddInfo: false,
      loading: false,
      message: null,
      showEntityId: null,
      isShowInstructions: false,
      errorSourceDocumentId: false,
      search: '',
      selectedVariantDataId: null,
      selected: {}
    }
  },
  computed: {
    ...mapGetters('projects', [
      'getFilter',
      'getEntitiesProject',
      'getLoadingEntitiesProject',
      'getErrorSendingNewInfo',
      'getEntitiesData'
    ]),
    ...mapGetters('documents', [
      'getReviewDocument',
      'getLoadingReviewDocument',
      'getErrorReviewDocument',
      'getDocument',
      'getLoadingDocument'
    ]),
    ...mapState('documents', ['loadingDocument', 'errorDocument']),
    projectId() {
      return this.$route.params.projectId || ''
    },
    drawSquaresBlue() {
      return this.getDocument.ocrData
    }
  },
  created() {
    const { projectId } = this.$route.params
    this.fetchEntitiesProject({ projectId })
  },
  methods: {
    ...mapActions('projects', ['fetchEntitiesProject', 'fetchEntitiesData']),
    showNotify(message, color, icon) {
      const { timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    },
    closeInstructions() {
      this.isShowInstructions = false
    },
    selectEntity(id) {
      const expanded = this.expanded !== id ? id : null
      this.$set(this, 'expanded', expanded)
    },
    selectData(data) {
      this.errorSourceDocumentId = false
      if (this.selectedEntityId !== data.entityId) {
        this.$set(this, 'selectedEntityId', data.entityId)
      }
      if (data && data.dataId) {
        this.$set(this, 'selectedDataLabel', data.dataLabel)
        const newSelectedDataId =
          this.selectedDataId === data.dataId ? null : data.dataId
        this.selectedDataId = newSelectedDataId
        this.selectedVariantDataId = data.dataId || null
      } else {
        this.selectedDataId = null
        this.selectedVariantDataId = null
      }
    },
    selectVariant(variant) {
      this.selectedVariantDataId = variant.dataId
    },
    setSearchText(val) {
      this.$set(this, 'search', val)
    },
    openAddEntity() {
      this.isAddEntity = true
    },
    closeAddEntity() {
      this.isAddEntity = false
    },
    openAddInfo() {
      this.isAddInfo = true
    },
    closeAddInfo() {
      this.isAddInfo = false
    },
    newEntity(entity) {
      const findEntity = this.entitiesProject.filter(
        _entity => _entity.entityId === entity.entityId
      )
      if (findEntity && !findEntity.length) {
        entity.custom = true
        const newEntitiesProject = [...this.entitiesProject, entity]
        this.$set(this, 'entitiesProject', newEntitiesProject)
      }
    },
    async checkSourceDocumentId(id) {
      if (id == 0) {
        this.errorSourceDocumentId = true
      } else {
        this.errorSourceDocumentId = false
      }
    },
    async saved(data) {
      this.errorSourceDocumentId = false
      await this.fetchEntitiesData({
        entityId: data.entityId,
        filter: this.getFilter
      })
      const entityData = this.getEntitiesData[data.entityId] || []
      if (entityData.length) {
        let indexCurrentData = entityData.findIndex(
          _data => _data.dataId === data.dataId
        )
        if (indexCurrentData === -1) {
          for (var entity of entityData) {
            const variant = entity.variants.find(
              _data => _data.dataId === data.dataId
            )
            if (variant && variant.dataId) {
              indexCurrentData = entityData.findIndex(
                _data => _data.dataId === entity.dataId
              )
            }
            if (indexCurrentData !== -1) {
              break
            }
          }
        }
        const nextData = entityData[indexCurrentData + 1]

        if (nextData) {
          nextData.entityId = data.entityId
          this.selectData(nextData)
        } else {
          const elCurrentEntity = document.getElementById(data.entityId)
          const dataEntityId = elCurrentEntity.nextSibling.getAttribute(
            'data-entity-id'
          )
          if (dataEntityId) {
            let firstData = this.getEntitiesData[dataEntityId]
            if (firstData === undefined) {
              await this.fetchEntitiesData({
                entityId: dataEntityId,
                filter: this.getFilter
              })
              firstData = this.getEntitiesData[dataEntityId]
            }

            this.$nextTick(function() {
              this.selectedEntityId = dataEntityId
              if (firstData && firstData.length) {
                this.selectedDataId = firstData[0].dataId
              }
            })
          }
        }
      }
    }
  },
  updated: function() {
    this.$nextTick(function() {
      const el = document.querySelector('.entity-item.custom')

      if (el) {
        // el.scrollIntoView({ block: 'center', behavior: 'smooth' })
        el.click()
        el.classList.remove('custom')
      }
      const el2 = document.querySelector('.entity.custom')
      if (el2) {
        el2.scrollIntoView({
          block: 'end',
          inline: 'end',
          alignToTop: false,
          behavior: 'smooth'
        })
        this.selectedEntityId = el2.getAttribute('data-entity-id')
      }
    })
  },
  watch: {
    'getDocument.extractedData': {
      immediate: true,
      handler: function(newExtractedData, oldExtractedData) {
        const _isEqual = isEqual(newExtractedData, oldExtractedData)
        if (!_isEqual) {
          this.extractedData = newExtractedData
        }
      }
    },
    entitiesProject: function(newEntities, oldEntities) {
      if (oldEntities === undefined) {
        this.selectedDataId = newEntities[0]?.entityData[0]?.dataId || null
      }
    },
    getErrorReviewDocument: function(message) {
      if (message && message.length) {
        const { color, icon } = this.$q.config.alert
        this.showNotify(message, color, icon)
      }
    },
    getReviewDocument: function(document) {
      if (document) {
        this.message = document[0].data.dataValue
      }
    },
    getEntitiesProject: {
      immediate: true,
      handler: function(newGetEntities) {
        this.entitiesProject = newGetEntities
      }
    }
  }
}
</script>
