<template>
  <form @submit.prevent="createEntity" class="add-entity-block">
    <div>
      <p v-if="!entityExist" class="title">Nom de la nouvelle entité</p>
      <p v-if="entityExist" class="title color-red">
        Cette entité existe déjà dans ce document
      </p>
      <Autocomplete
        label="Entity name"
        @setEditLabelTitle="setEditLabelTitle"
      />
    </div>
    <div>
      <p class="title">Type d'entité</p>
      <q-select
        :popup-content-class="'entity-popup'"
        @@input-value="() => this.$refs.select.blur()"
        ref="select"
        filled
        dense
        v-model="entityType"
        fill-input
        input-debounce="0"
        :options="options"
        lazy-rules
        :rules="[val => (val && val.length > 0) || 'Veuillez faire un choix']"
      />
    </div>

    <div class="bottom-buttons">
      <q-btn label="Annuler" class="btn-cancel" @click="closeBlock" />

      <q-btn
        :disable="entityExist"
        label="Créer"
        class="btn-see"
        type="submit"
      >
      </q-btn>
    </div>
  </form>
</template>

<script>
import Autocomplete from '@/components/review-information/Autocomplete'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AddEntityBlock',
  components: {
    Autocomplete
  },
  props: ['projectId', 'extractedData'],
  computed: {
    ...mapGetters('projects', ['getEntitiesProject'])
  },
  data() {
    return {
      entityName: '',
      entityType: 'Personne morale',
      options: ['Personne morale', 'Personne physique'],
      entityExist: false
    }
  },
  mounted() {
    const body = document.querySelector('body')
    body.classList.add('placeholders')
    // prevent click outside event with popupItem.
    this.popupItem = this.$el
  },
  methods: {
    ...mapActions('documents', ['addEntity']),
    setEditLabelTitle(value) {
      this.entityName = value
      if (value) {
        const data = this.extractedData || this.getEntitiesProject
        const entity = data.filter(entity => entity.entityName == value)
        if (entity && entity.length) {
          this.entityExist = true
        } else {
          if (this.entityExist) {
            this.entityExist = false
          }
        }
      }
    },
    closeBlock() {
      this.$emit('close')
    },
    createEntity(ee) {
      this.addEntity({
        projectId: this.projectId,
        entityName: this.entityName,
        entityType: this.entityType
      })
        .then(response => {
          this.$emit('newEntity', {
            entityName: this.entityName,
            entityId: response.entityId,
            entityData: [],
            entityType: this.entityType
          })
          this.$emit('close')
        })
        .catch(error => {
          if (error.status === 409) {
            const entityId = error.data.error.detail.entityId
            this.$emit('newEntity', {
              entityName: this.entityName,
              entityId: entityId,
              entityData: [],
              entityType: this.entityType
            })
            this.$emit('close')
          } else {
            const { colorEmpty, iconEmpty } = this.$q.config.alert
            this.showNotify(
              `${error.data.error.message}`,
              colorEmpty,
              iconEmpty
            )
          }
        })
    },
    showNotify(message, color, icon) {
      const { timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    }
  },
  watch: {
    title: function() {
      this.$refs.select.blur()
    }
  }
}
</script>
