<template>
  <q-form class="search" @submit.prevent="">
    <q-input
      v-model="searchText"
      debounce="700"
      filled
      :disable="disabled"
      :placeholder="placeholder"
      class="search"
      ref="search"
    >
      <template v-slot:before>
        <span class="filter-search-icon"></span>
      </template>
      <template v-slot:append>
        <q-icon name="close" @click="clear" />
      </template>
    </q-input>
  </q-form>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'SearchInformation',
  props: {
    placeholder: {
      type: String,
      default: 'Search information'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    reset: {
      type: Boolean,
      default: false
    },
    global: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchText: '',
      preloader: false
    }
  },
  methods: {
    ...mapActions('projects', ['setSearch', 'fetchAllData', 'closeEntities']),
    ...mapActions('templates', ['setSearchTemplates']),
    ...mapActions('documents', ['setSearchDocuments', 'setSearchEntity']),
    ...mapActions('users', ['setSearchUser']),
    clear() {
      if (!this.searchText) return

      this.searchText = ''
      this.$refs.search.blur()
      this.$nextTick(() => {
        this.preloader = false
      })
      this.closeEntities({ status: true })
      setTimeout(() => this.closeEntities({ status: false }), 200)
    },
    setGlobalSearch() {
      const route = this.$route

      switch (route.name) {
        case 'Projects':
          this.setSearch({ message: this.searchText.toLowerCase().trim() })
          break
        case 'Project':
          this.setSearch({ message: this.searchText.toLowerCase().trim() })
          break
        case 'Templates':
          this.setSearchTemplates({
            message: this.searchText.toLowerCase().trim()
          })
          break
        case 'Documents':
          this.setSearchDocuments({
            message: this.searchText.toLowerCase().trim()
          })
          break
        case 'UserManagement':
          this.setSearchUser({
            message: this.searchText.toLowerCase().trim()
          })
          break
        case 'InformationsManagement':
          this.setSearchUser({
            message: this.searchText.toLowerCase().trim()
          })
          break
        case 'Pdf':
          this.setSearchEntity({
            message: this.searchText.toLowerCase().trim()
          })
          break
        default:
          break
      }
    }
  },
  watch: {
    searchText: function(value) {
      // if (this.global) {
      //   this.setGlobalSearch()
      // }

      this.$emit('setSearchText', this.searchText.toLowerCase().trim())

      if (!value) {
        this.preloader = false
        return
      }

      if (this.loading) {
        this.preloader = true
        // this.fetchAllData({ status: true })
      }
    },
    reset: function(status) {
      if (status) {
        this.searchText = ''
        this.$nextTick(() => {
          this.preloader = false
        })
      }
    },
    getFetchAllData: function(status) {
      if (!status) {
        this.preloader = false
      }
    }
  }
}
</script>
