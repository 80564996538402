<template>
  <div
    :class="{ entity: true, custom: entity.custom }"
    :id="`${entity.entityId}`"
  >
    <q-expansion-item
      :value="entity.entityId === selectedEntityId || index === 0 || isSearch"
      switch-toggle-side
      :label="entity.entityName"
      @show="selectEntity(entity)"
      @hide="showEntityId = null"
    >
      <template v-slot:header>
        <q-item-section>
          <div
            class="wrapper-title"
            @mouseover="hover = true"
            @mouseleave="hover = false"
          >
            <div v-if="!isEditEntity" :class="{ hover: true }">
              <span class="title">
                {{ entity.entityName }}
              </span>
            </div>
            <button
              v-if="hover && !isEditEntity"
              class="edit-btn__custom"
              @click="editEntity(entity)"
            >
              <img src="../../assets/icons/edit.svg" alt="" />
            </button>
          </div>
          <span v-if="isEditEntity" class="title">
            <form
              class="form-rename-entity"
              @submit.prevent="saveLabel"
              @click.stop=""
            >
              <AutocompleteEntity
                :edit-label-title="editLabelTitle"
                @setEditLabelTitle="setEditLabelTitle"
              />
              <q-btn class="edit-btn__custom" @click="isEditEntity = false">
                <q-icon name="close" />
              </q-btn>
              <q-btn
                type="submit"
                class="btn-save"
                :disable="!editLabelTitle.length"
              >
                <q-icon class="save-icon" />
              </q-btn>
            </form>
          </span>
          <q-tooltip v-if="isShowTooltip(`${entity.entityName}`)">
            {{ entity.entityName }}
          </q-tooltip>
        </q-item-section>
        <q-btn
          v-show="entity.entityId === showEntityId && !isEditEntity"
          icon-right="add"
          label="Ajouter une info"
          class="btn-white"
          :loading="loadingOpenAddInfo"
          @click.stop="openAddInfo(entity)"
        />
      </template>
      <div v-if="isAddInfo">
        <AddInfoBlock
          :entity="entity"
          @close="closeAddInfo"
          @newInfo="addNewInfo"
        />
      </div>
      <q-card v-if="entityData && entityData.length > 0">
        <q-card-section
          v-for="data in entityData"
          :data-entity-data-id="data.dataId"
          :key="data.dataId"
          :id="data.dataId"
          :class="{
            'entity-item': true,
            active: data.dataId === selectedDataId,
            custom: data.dataId.includes('CUSTOM')
          }"
        >
          <div class="top" :id="data.dataId">
            <div class="right" @click="e => selectData(e, data)">
              <span :class="['status', data.status]"></span>
              <div class="right-left">
                <div class="label">{{ data.dataLabel }}</div>
                <div class="value">{{ data.dataValue }}</div>
                <div class="label">Source: {{ data.sourceDocumentType }}</div>
              </div>
            </div>
            <div class="bottom" v-if="data.dataId === selectedDataId">
              <div
                v-for="(variant, index) in data.variants"
                :key="variant.dataId"
                @click="selectVariant(variant)"
              >
                <div class="variant-content">
                  <q-item v-ripple>
                    <q-item-section class="content__status">
                      <span :class="['status', variant.status]"></span>
                    </q-item-section>
                    <q-item-section class="content__item">
                      <div class="wrapper-radio">
                        <q-radio
                          :value="radio"
                          :val="variant.dataId"
                          @click.native="selectRadio(variant, variant.dataId)"
                        />
                      </div>
                      <div class="content_desc">
                        <q-item-label>{{ variant.dataValue }}</q-item-label>
                        <q-item-label caption
                          >Source: {{ variant.sourceDocumentType }},
                          {{ variant.sourceDocumentDate }}</q-item-label
                        >
                      </div>
                    </q-item-section>
                  </q-item>
                </div>
                <div
                  v-if="index !== data.variants.length - 1"
                  class="wrapper-separator"
                >
                  <q-separator spaced />
                </div>
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
      <q-card v-else>
        <q-card-section class="entity-item-empty">
          <LoadingOrEmpty
            :loading="loading"
            :emptyContent="'Aucune information pour cette entité'"
          >
          </LoadingOrEmpty>
        </q-card-section>
      </q-card>
    </q-expansion-item>
  </div>
</template>

<script>
import { defaultFilter } from '@/store/modules/projects/state'
import { mapActions, mapGetters } from 'vuex'
import LoadingOrEmpty from '@/components/global/LoadingOrEmpty'
import AddInfoBlock from '@/components/review-information/AddInfoBlock'
import AutocompleteEntity from '@/components/project/AutocompleteEntity'

export default {
  name: 'EntityBlock',
  components: { LoadingOrEmpty, AddInfoBlock, AutocompleteEntity },
  props: {
    typeReview: {
      type: String,
      default: () => 'material'
    },
    entity: {
      type: Object,
      default: () => {}
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    entityId: {
      type: String,
      default: () => ''
    },
    selectedDataId: {
      type: String,
      default: () => ''
    },
    index: {
      type: Number,
      required: true
    },
    selectedEntityId: {
      type: String,
      default: () => ''
    },
    search: {
      type: String,
      default: () => ''
    },
    selectedVariantDataId: {
      type: String,
      default: () => null
    }
  },
  data() {
    return {
      expanded: this.isOpen,
      show: false,
      radio: false,
      currentId: '',
      isActive: '',
      showEntityId: null,
      localSelectedEntityId: null,
      entityData: [],
      loading: false,
      isAddInfo: false,
      hover: false,
      isEditEntity: false,
      editLabelTitle: '',
      loadingOpenAddInfo: false,
      isSearch: false
    }
  },
  async created() {
    const { filterDataConfidences } = this.$route.query

    // this.updateFilter({
    //   filter: {
    //     ...defaultFilter,
    //     filterDataConfidences: filterDataConfidences
    //       ? [filterDataConfidences]
    //       : []
    //   }
    // })

    // await this.fetchEntitiesData({
    //   entityId: this.entity.entityId,
    //   filter: this.getFilter
    // })

    // const { projectId } = this.$route.params
    // await this.fetchEntitiesProject({ projectId })
    // console.log('getEntitiesData', this.getEntitiesData)
    // if (Object.keys(this.getEntitiesData[this.entity.entityId]).length) {
    //   this.show = true
    // }

    // if (this.expanded) {
    //   this.fetchReviewDocument({
    //     document: this.show
    //       ? this.getEntitiesData[this.entity.entityId][0]
    //       : null
    //   })
    // }
  },
  computed: {
    ...mapGetters('projects', [
      'getFilter',
      'getEntitiesData',
      'getLoadingEntitiesData',
      'getErrorSendingNewInfo',
      'getErrorPutEntityItem'
    ]),
    ...mapGetters('documents', [
      'getReviewDocument',
      'getLoadingReviewDocument',
      'getDocument',
      'getLoadingDocument'
    ])
  },
  methods: {
    ...mapActions('projects', [
      'fetchEntitiesData',
      'updateFilter',
      'putAnalyse',
      'renameEntity',
      'fetchEntitiesProject',
      'fetchPlaceholders',
      'putEntityItem'
    ]),
    ...mapActions('documents', ['fetchReviewDocument', 'fetchDocument']),
    async selectEntity(entity) {
      const el = document.querySelector('.entity-item.active')
      if (el) {
        el.classList.remove('active')
        this.$emit('selected', {})
      }

      this.loading = true
      await this.fetchEntitiesData({
        entityId: this.entity.entityId,
        filter: this.getFilter
      })
      this.loading = false
      if (!this.isSearch) {
        const entityData = this.getEntitiesData[this.entity.entityId] || []
        this.entityData = entityData
      }

      this.localSelectedEntityId = entity.entityId
      this.showEntityId = entity.entityId
    },
    selectData(e, _data) {
      _data.entityId = this.localSelectedEntityId
      this.$emit('selected', _data)
    },
    async openAddInfo(e) {
      this.loadingOpenAddInfo = true
      await this.fetchPlaceholders()
      this.isAddInfo = true
      this.loadingOpenAddInfo = false
    },
    async selectElement(item) {
      if (this.getLoadingReviewDocument) return

      this.isActive = item.dataId
    },
    async selectVariant(variant) {
      if (variant.sourceDocumentId != 0) {
        if (
          (this.getDocument &&
            this.getDocument.documentId !== variant.sourceDocumentId) ||
          this.getDocument === null
        ) {
          await this.fetchDocument({ documentId: variant.sourceDocumentId })
        }
      } else {
        this.$emit('sourceDocumentId', variant.sourceDocumentId)
      }

      this.$emit('selectedVariant', variant)
    },
    async selectRadio(variant) {
      const data = {
        dataId: variant.dataId,
        entityId: this.entity.entityId,
        dataValue: variant.dataValue,
        dataDefault: true,
        sourceDocumentDate: variant.sourceDocumentDate,
        sourceDocumentType: variant.sourceDocumentType
      }

      try {
        await this.putEntityItem(data)
        await this.fetchEntitiesData({
          entityId: this.entity.entityId,
          filter: this.getFilter
        })
        const entityData = this.getEntitiesData[this.entity.entityId] || []
        this.entityData = entityData
        this.selectData(null, data)
      } catch (err) {
        this.showNotify('Update failed')
      }
    },
    addNewInfo(info) {
      const newEntityData = [...this.entityData]
      newEntityData.push({
        ...info,
        status: 'MANUAL',
        dataLabel: info.dataLabel,
        dataValue: '',
        dataId: info.dataId,
        entityId: info.entityId,
        sourceDocumentDate: info.sourceDocumentDate,
        variants: []
      })
      this.entityData = newEntityData
      this.isAddInfo = false
    },
    closeAddInfo() {
      this.isAddInfo = false
    },
    isShowTooltip(title) {
      if (title.length > 30) {
        return true
      }
      return false
    },
    editEntity(entity) {
      this.isEditEntity = true
      this.editLabelTitle = entity.entityName
    },
    async saveLabel() {
      const data = {
        ...this.entity,
        entityName: this.editLabelTitle
      }

      this.renameEntity(data)
      this.isEditEntity = false

      const { projectId } = this.$route.params
      await this.fetchEntitiesProject({ projectId })
    },
    setEditLabelTitle(title) {
      this.editLabelTitle = title
    },
    showNotify(message) {
      const { color, icon, timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    },
    buildSearchData(message) {
      if (message && message.length) {
        this.isSearch = true

        const entities = this.getEntitiesData[this.entity.entityId] || []

        const data =
          entities.filter(item => {
            // console.log('item', item)
            if (
              item.dataLabel.toLowerCase().includes(message) ||
              item.dataValue.toLowerCase().includes(message) ||
              item.sourceDocumentType.toLowerCase().includes(message) ||
              item.sourceDocumentDate.includes(message)
            ) {
              return item
            } else {
              const findVariant = item.variants.filter(variant => {
                if (
                  variant.dataLabel.toLowerCase().includes(message) ||
                  variant.dataValue.toLowerCase().includes(message) ||
                  variant.sourceDocumentType.toLowerCase().includes(message) ||
                  variant.sourceDocumentDate.includes(message)
                ) {
                  return variant
                }
              })

              if (findVariant && findVariant.length) {
                return item
              }
            }
          }) || []

        this.$set(this, 'entityData', data)
        this.$emit('selected', {})
      } else {
        this.isSearch = false
        this.entityData = this.getEntitiesData[this.entity.entityId] || []
      }
    }
  },
  updated: function() {
    this.$nextTick(function() {
      const el = document.querySelector('.entity-item.custom')
      if (el) {
        el.click()
        el.scrollIntoView({ block: 'center', behavior: 'smooth' })

        const localSelectedDataId = el.getAttribute('data-entity-data-id')

        if (localSelectedDataId === this.selectedDataId) {
          return
        }
        const data = this.entityData.find(
          data => data.dataId == localSelectedDataId
        )
        this.$emit('selected', data)
        el.click()
        el.classList.remove('custom')
      }
    })
    this.$nextTick(function() {
      const el = document.querySelector('.entity-item.active')
      if (el) {
        el.scrollIntoView({ block: 'center', behavior: 'smooth' })
      }
    })
    this.$nextTick(function() {
      const el = document.querySelector('.entity.custom')
      if (el) {
        el.scrollIntoView({ block: 'center', behavior: 'smooth' })
        this.localSelectedEntityId = el.getAttribute('data-entity-id')
        el.classList.remove('custom')
      }
    })
  },
  watch: {
    getErrorPutEntityItem: function(message) {
      if (message && message.length) this.showNotify(message)
    },
    index: {
      immediate: true,
      handler: function(newIndex, oldIndex) {
        if (newIndex === 0 && oldIndex === undefined) {
          this.selectEntity(this.entity).then(() => {
            const entityData = this.getEntitiesData[this.entity.entityId] || []
            const data = entityData[0] || {}
            data.entityId = this.localSelectedEntityId
            this.$emit('selected', data)
          })
        }
      }
    },
    selectedDataId: {
      immediate: true,
      handler: function(newSelectedDataId, oldSelectedDataId) {
        if (this.isSearch) {
          this.radio = newSelectedDataId
          return
        }
        if (
          this.localSelectedEntityId !== this.entity.entityId ||
          newSelectedDataId === oldSelectedDataId
          // this.selectedEntityId !== this.entity.entityId ||
        ) {
          this.radio = newSelectedDataId
          return
        }
        // this.localSelectedEntityId = this.selectedEntityId

        const data = this.getEntitiesData[this.selectedEntityId]
        if (data && data.length) {
          const entityData =
            data && data.find(data => data.dataId === newSelectedDataId)

          if (entityData) {
            const defaultVariant =
              entityData &&
              entityData.variants.find(variant => variant.dataDefault === true)
            if (defaultVariant) {
              this.radio = defaultVariant.dataId
              this.selectVariant(defaultVariant)
            }
          }
        }
      }
    },
    selectedVariantDataId: {
      immediate: true,
      handler: function(newSelectedVariantDataId, oldSelectedVariantDataId) {
        if (newSelectedVariantDataId === oldSelectedVariantDataId) {
          return
        }
        // if (newSelectedVariantDataId !== null) {
        //   this.radio = newSelectedVariantDataId
        // }
        // this.radio = newSelectedVariantDataId
        if (
          newSelectedVariantDataId &&
          !newSelectedVariantDataId.includes('CUSTOM') &&
          !this.isSearch
        ) {
          const entityData = this.getEntitiesData[this.entity.entityId] || []
          this.entityData = entityData
        }
      }
    },
    search: {
      immediate: true,
      handler: function(newSearchVal, oldSearchVal) {
        if (!newSearchVal.length && oldSearchVal === undefined) {
          return
        }
        this.isSearch = true
        if (this.showEntityId === null) {
          this.selectEntity(this.entity)
        }

        this.buildSearchData(newSearchVal)
      }
    }
  }
}
</script>
