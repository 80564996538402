<template>
  <q-select
    :label="label"
    filled
    v-model="title"
    use-input
    hide-selected
    fill-input
    input-debounce="0"
    :options="options"
    @filter="filterFn"
    @input-value="setModel"
    :placeholder="placeholder"
    counter
    :maxlength="maxLength"
    lazy-rules
    :rules="[val => check() || 'Veuillez nommer l\'entité']"
  >
  </q-select>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    editLabelTitle: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    maxLength: {
      type: Number,
      default: 2000
    }
  },
  data() {
    return {
      options: [],
      originOptions: [],
      title: this.editLabelTitle
    }
  },
  async created() {
    const body = document.querySelector('body')
    body.classList.add('placeholders')
    await this.fetchAnalyses()

    let result = []
    this.getAnalyses.forEach(analysis => {
      // if (result.length) {
      //   // if exists
      //   const index = result.findIndex(
      //     item => item.entityName === analysis.entityName
      //   )
      //   if (index > -1) return
      // }

      result.push({
        label: analysis.entityName,
        value: analysis.entityName
      })
    })

    result = result.sort((a, b) => a.label.localeCompare(b.label))
    this.options = this.originOptions = result
  },
  computed: {
    ...mapGetters('projects', ['getAnalyses'])
  },
  methods: {
    ...mapActions('projects', ['fetchAnalyses']),
    filterFn(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase()
        this.options = this.originOptions.filter(
          v => v.label.toLocaleLowerCase().indexOf(needle) > -1
        )
        //.sort((a, b) => a.label.localeCompare(b.label))
      })
    },
    setModel(val) {
      this.title = val
      this.$emit('setEditLabelTitle', this.title)
    },
    check() {
      if (this.title.value == undefined) {
        return (
          (this.title && this.title.trim().length) ||
          typeof this.title === 'object'
        )
      } else {
        return (
          (this.title && this.title.value.trim().length) ||
          typeof this.title === 'object'
        )
      }
    }
  },
  watch: {
    editLabelTitle: function(val) {
      this.title = val
    }
  }
}
</script>
