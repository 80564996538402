<template>
  <q-select
    dense
    ref="select"
    filled
    v-model="title"
    use-input
    hide-selected
    fill-input
    input-debounce="0"
    :options="options"
    @filter="filterFn"
    @input-value="setModel"
    :placeholder="placeholder"
    lazy-rules
    :rules="[
      val =>
        (val && ((val.value && val.value.length > 0) || val.length > 0)) ||
        'Obligatoire'
    ]"
  >
  </q-select>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Autocomplete',
  props: {
    editLabelTitle: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      options: [],
      originOptions: [],
      title: this.editLabelTitle
    }
  },
  async created() {
    await this.fetchAnalyseEntity()
    const options = this.getAnalyseEntity
      .map(entity => {
        return {
          label: entity.entityName,
          value: entity.entityName
        }
      })
      .sort((a, b) => a.label.localeCompare(b.label))
    this.originOptions = options
    this.$set(this, 'options', options)
  },
  computed: {
    ...mapGetters('documents', [
      'getAnalyseEntity',
      'getErrorAnalyseEntityList'
    ])
  },
  methods: {
    ...mapActions('documents', ['fetchAnalyseEntity']),
    filterFn(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase()
        this.options = this.originOptions.filter(
          v => v.label.toLocaleLowerCase().indexOf(needle) > -1
        )
      })
    },
    setModel(val) {
      this.title = val.value || val
      this.$emit('setEditLabelTitle', this.title)
    }
  },
  watch: {
    editLabelTitle: function(val) {
      this.title = val
    },
    title: function(val) {
      console.log(val, 'title')
    }
  }
}
</script>
