<template>
  <div class="add-info-block">
    <p class="title">Intitulé de l'information</p>
    <q-form @submit="onSubmit">
      <q-select
        dense
        :loading="getLoadingPlaceholders"
        ref="select"
        filled
        v-model="title"
        use-input
        hide-selected
        fill-input
        input-debounce="0"
        :options="options"
        @input-value="setModel"
        @filter="filterFn"
        lazy-rules
        :rules="[
          val =>
            (val && ((val.value && val.value.length > 0) || val.length > 0)) ||
            'Veuillez entrer une information'
        ]"
        @keydown.enter.exact.prevent="onSubmit"
      />
      <div class="bottom-buttons">
        <q-btn label="Annuler" class="btn-cancel" @click="closeBlock" />

        <q-btn
          label="Ajouter"
          :disable="getLoadingPlaceholders"
          class="btn-see"
          type="submit"
        />

      </div>
    </q-form>
  </div>
</template>

<script>
import LoadingOrEmpty from '@/components/global/LoadingOrEmpty'
import format from 'date-fns/format/index.js'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AddInfoBlock',
  components: {
    LoadingOrEmpty
  },
  props: {
    entity: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      title: '',
      options: [],
      originOptions: []
    }
  },
  computed: {
    ...mapGetters('projects', ['getLoadingPlaceholders', 'getPlaceholders'])
  },
  created() {
    const body = document.querySelector('body')
    body.classList.add('placeholders')

    let result = []
    this.getPlaceholders.forEach(placeholder => {
      placeholder.categoryLabels.forEach(label => {
        if (result.length) {
          // if exists
          const index = result.findIndex(item => item.label === label.dataLabel)
          if (index > -1) return
        }

        result.push({
          label: label.dataLabel,
          value: label.dataLabel
        })
      })
    })
    result = result.sort((a, b) => a.label.localeCompare(b.label))

    this.options = result
    this.originOptions = result
  },
  methods: {
    ...mapActions('projects', ['fetchPlaceholders']),
    filterFn(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase()
        const options = this.originOptions.filter(
          v => v.label.toLocaleLowerCase().indexOf(needle) > -1
        )
        this.options = options
      })
    },
    setModel(val) {
      this.title = val.value || val
    },
    onSubmit() {
      const sourceDocumentDate = format(new Date(), 'dd/MM/yyyy')
      const newInfo = {
        dataLabel: this.title.label || this.title,
        entityId: this.entity.entityId,
        dataId: 'CUSTOM' + new Date().getTime(),
        sourceDocumentDate: sourceDocumentDate,
        sourceDataLocation: []
      }
      this.$emit('newInfo', newInfo)
      this.$emit('close')
    },
    closeBlock() {
      this.$emit('close')
    }
  }
}
</script>

<style></style>
